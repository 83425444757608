import { Grid, GridItem, Spacing } from '@loomispay/vault';
import { t } from 'i18next';
import { Permission } from 'permissions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { formatISODateTime } from 'utils/formatters/dateFormatters';
import { StatusCode } from '../../../api/types';
import { Contract } from '../details/MerchantDetails';
import { Spaced, TitledList, Wrapper } from '../details/MerchantScreen.styles';
import { Tooltip } from '../fees/helper';
import { useMerchantIdFromUrl, useMerchantStatus } from '../merchant-hooks';
import { useMerchant } from '../merchantReporting-hooks';
import { EnabledFeature, useConfiguration } from '../../../configuration';
import { OffboardMerchantButton } from './OffboardMerchantButton';
import { RetrySalesforceOnboardingButton } from './RetrySalesforceOnboardingButton';
import { AmexOnboardingButton } from './AmexOnboardingButton';
import { SubmitSignerDataButton } from './SubmitSignerDataButton';
import { AmexFeeButton } from './AmexFeeButton';
import { AmexOffboardingButton } from './AmexOffboardingButton';

export const MerchantBoardingTab = () => {
  const { data } = useConfiguration();

  const merchantData = useMerchant(useMerchantIdFromUrl()).data;
  const statusCode = merchantData?.merchant?.status?.code;
  const onboardingStep = merchantData?.onboarding?.step;

  const offboardingVisible =
    statusCode === StatusCode.OFFBOARDING_REQUESTED ||
    statusCode === StatusCode.OFFBOARDED ||
    statusCode === StatusCode.ANNULLED;
  const offboardingButtonWithTooltipVisible =
    statusCode === StatusCode.OFFBOARDED || statusCode === StatusCode.DEACTIVATED || statusCode === StatusCode.ANNULLED;
  const offboardingButtonVisible = onboardingStep != null && onboardingStep >= 60;

  return (
    <>
      <Grid gutter={0}>
        <GridItem l={12} m={8} s={4}>
          <Grid gutter={0}>
            {/*<Status />*/}
            <Contract />
            <Activity />
            {offboardingVisible && <Offboarding />}
          </Grid>
        </GridItem>
      </Grid>
      <Spacing bottom="4" />
      <Grid gutter={0}>
        <GridItem l={6} m={4} s={4}>
          <Grid gutter={0}>
            <ButtonsPanel>
              {data?.permissions.includes(Permission.OFFBOARD_MERCHANT) && (
                <>
                  {(offboardingButtonWithTooltipVisible && (
                    <>
                      <Tooltip data-hover={t('merchant.details.status.offboard.tooltip')}>
                        <OffboardMerchantButton />
                      </Tooltip>
                      <Spacing right="4" />
                    </>
                  )) ||
                    (offboardingButtonVisible && (
                      <>
                        <OffboardMerchantButton />
                        <Spacing right="4" />
                      </>
                    ))}
                </>
              )}
              <RetrySalesforceOnboardingButton />
            </ButtonsPanel>
          </Grid>
        </GridItem>
        <GridItem l={6} m={4} s={4}>
          <ButtonsPanel>
            <AmexOnboardingButton />
            <Spacing right="4" />
            <SubmitSignerDataButton />
            <Spacing right="4" />
            {data?.enabledFeatures?.includes(EnabledFeature.AMEX_OFFBOARDING) && (
              <>
                <AmexOffboardingButton />
                <Spacing right="4" />
              </>
            )}
            <AmexFeeButton />
          </ButtonsPanel>
        </GridItem>
      </Grid>
    </>
  );
};

const ButtonsPanel = styled.div`
  display: flex;
  flex-flow: row wrap;
  row-gap: 1em;
`;

const Activity = () => {
  const { t } = useTranslation();
  const { data } = useMerchantStatus();

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'activity'} header={'Activity'} icon={'calendar'}>
          <Wrapper>
            <Spaced
              title={t('merchant.details.status.last-transaction-at')}
              value={data?.lastTransactionOccurredAt ? formatISODateTime(data.lastTransactionOccurredAt) : t('never')}
            />
            <Spaced
              title={t('merchant.details.status.number-of-invoices')}
              value={data?.numberOfInvoices ? data?.numberOfInvoices.toString() : '0'}
            />
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};

const Offboarding = () => {
  const { t } = useTranslation();
  const { data } = useMerchant(useMerchantIdFromUrl());

  return (
    <GridItem l={6} m={4} s={4}>
      <Spacing right={'4'}>
        <TitledList data-testid={'offboarding'} header={'Offboarding'} icon={'close'}>
          <Wrapper>
            {data?.merchant.status?.code === StatusCode.OFFBOARDING_REQUESTED && (
              <Spaced
                title={t('merchant.details.status.offboard.requested.at')}
                value={
                  data?.merchant.status?.timestamp ? formatISODateTime(data?.merchant.status?.timestamp.toString()) : ''
                }
              />
            )}
            {data?.merchant.status?.code === StatusCode.OFFBOARDED && (
              <Spaced
                title={t('merchant.details.status.offboard.gateway.at')}
                value={
                  data?.merchant.status?.timestamp ? formatISODateTime(data?.merchant.status?.timestamp.toString()) : ''
                }
              />
            )}
            {data?.merchant.status?.code === StatusCode.ANNULLED && (
              <Spaced
                title={t('merchant.details.status.offboard.annulled.at')}
                value={
                  data?.merchant.status?.timestamp ? formatISODateTime(data?.merchant.status?.timestamp.toString()) : ''
                }
              />
            )}
          </Wrapper>
        </TitledList>
      </Spacing>
    </GridItem>
  );
};
