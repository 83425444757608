import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Display } from '@loomispay/vault';
import { FormTextInput } from 'components/TextInput';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createToast } from 'vercel-toast';
import { z, ZodIssueCode } from 'zod';
import { fetchFromBackend } from '../../utils/fetch';
import { FormSelect } from 'components/Select';
import styled from 'styled-components';
import {
  BankAccountInformationUpdateRequest,
  BankAccountInformationUpdateRequests,
  GetBankAccountInformationUpdateRequests,
  RequestStatus,
} from './BankAccountInformationUpdateRequests';

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexLeftCol = styled.div`
  width: 25%;
  padding-right: 1.8%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1%;
`;

const formSchema = z
  .object({
    merchantId: z.string().optional(),
    status: z.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.merchantId !== undefined) {
      if (
        !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(val.merchantId) &&
        val.merchantId.length !== 0
      )
        ctx.addIssue({
          code: ZodIssueCode.custom,
          message: 'Invalid UUID',
          path: ['merchantId'],
        });
    }
  });

type FormInputSchema = z.infer<typeof formSchema>;

const filterFields = (obj: { [key: string]: string | undefined }) => {
  return Object.keys(obj).reduce((acc, key) => {
    const _acc: { [key: string]: string | undefined } = acc;
    if (obj[key] !== undefined && obj[key] !== '') _acc[key] = obj[key];
    return _acc;
  }, {});
};

const constructUrl = (formData: FormInputSchema): string => {
  const optionalQueryParams = {
    merchantId: formData.merchantId,
    status: formData.status,
  };

  return (
    '/bank-account-information-update-request?' + new URLSearchParams(filterFields(optionalQueryParams)).toString()
  );
};

const BankAccountInformationUpdateRequestSearch = () => {
  const { t } = useTranslation();
  const [bankAccountUpdateRequests, setBankAccountUpdateRequests] = useState<BankAccountInformationUpdateRequest[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const { control, handleSubmit, getValues } = useForm<FormInputSchema>({
    defaultValues: {
      merchantId: undefined,
      status: RequestStatus.NEW,
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormInputSchema) => {
    const url = constructUrl(data);
    setIsFetching(true);
    const response = await fetchFromBackend(url);

    if (response.ok) {
      const responseBody: GetBankAccountInformationUpdateRequests = await response.json();
      const bankAccountUpdateRequests = responseBody.data;

      if (bankAccountUpdateRequests.length === 0) {
        createToast(`${t('bank-account-update-requests.table.empty')}`, {
          timeout: 6000,
          type: 'dark',
        });
      }
      setBankAccountUpdateRequests(responseBody.data);
    } else {
      createToast(t('bank-account-update-requests.table.error'), {
        timeout: 6000,
        type: 'error',
      });
    }

    setIsFetching(false);
  };

  useEffect(() => {
    onSubmit(getValues());
    // this hook should only be triggered on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Display size="s">{t('bank-account-update-requests.title')}</Display>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FlexDiv>
          <FlexLeftCol>
            <FormSelect
              options={[
                { value: undefined, label: 'ALL' },
                ...Object.values(RequestStatus).map(it => ({ value: it, label: it })),
              ]}
              label={t('bank-account-update-requests.table.status')}
              name="status"
              control={control}
            />
          </FlexLeftCol>
          <FlexLeftCol>
            <FormTextInput
              label={t('bank-account-update-requests.table.merchant-id')}
              placeholder="0f14d0ab-9605-4a62-a9e4-5ed26688389a"
              name={'merchantId'}
              control={control}
            />
          </FlexLeftCol>
        </FlexDiv>
        <div>
          <Button type={'submit'} label={t('bank-account-update-requests.table.search')} disabled={isFetching} />
        </div>
      </form>
      <BankAccountInformationUpdateRequests
        bankAccountUpdateRequests={bankAccountUpdateRequests}
        refresh={() => onSubmit(getValues())}
      />
    </div>
  );
};

export default BankAccountInformationUpdateRequestSearch;
