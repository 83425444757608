import { useState } from 'react';
import { Button, Heading, Text, Toggle } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import { createToast } from 'vercel-toast';
import { fetchFromBackend } from '../../utils/fetch';
import { BankAccountUpdateRequestActionProps } from './BankAccountUpdateRequestActionProps';
import { Modal } from 'components/Modal';
import { CloseButton } from '../fees/helper';
import styled from 'styled-components';

export const ToggleContainer = styled.div`
  padding: 10px 0 25px;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
`;

export const ApproveBankAccountChangeButton = ({ request, onAction }: BankAccountUpdateRequestActionProps) => {
  const { t } = useTranslation();
  const [loadModal, setLoadModal] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);

  const url = `/bank-account-information-update-request/${request.id}/approve`;

  const approveBankAccountChange = async () => {
    await fetchFromBackend(url, {
      method: 'PUT',
    }).then(response => {
      if (response.ok) {
        setLoadModal(false);
        createToast(t('bank-account-update-requests.table.approve.success'), {
          timeout: 10000,
          type: 'success',
        });
        onAction();
      } else {
        createToast(t('bank-account-update-requests.table.approve.error'), {
          timeout: 6000,
          type: 'error',
        });
      }
    });
  };

  return (
    <>
      <Button
        label={t('bank-account-update-requests.table.approve.action')}
        onClick={() => {
          setLoadModal(true);
        }}
        variant={'positive'}
      />
      <Modal isOpen={loadModal} close={() => setLoadModal(false)}>
        <CloseButton>
          <Button
            icon={'close'}
            variant={'tertiary'}
            onClick={() => {
              setLoadModal(false);
            }}
          />
        </CloseButton>
        <Heading>{t('bank-account-update-requests.table.approve.modal.heading')}</Heading>
        <FlexCol>
          <Text>{t('bank-account-update-requests.table.approve.modal.subheading')}</Text>
          <Text>{request.bankIdentificationCode}</Text>
          <Text>{request.bankAccountNumber}</Text>
          <Text>{request.accountOwner}</Text>
        </FlexCol>
        <ToggleContainer>
          <Toggle
            inputProps={{ value: `` + confirmed }}
            label={t('bank-account-update-requests.table.approve.modal.confirmation')}
            onChange={value => {
              setConfirmed(value);
            }}
          />
        </ToggleContainer>
        <Button
          disabled={!confirmed}
          label={t('bank-account-update-requests.table.approve.action')}
          onClick={() => {
            approveBankAccountChange();
          }}
        />
      </Modal>
    </>
  );
};
