import { fetchByUrl } from 'api';
import { Permission } from 'permissions';
import useSWR from 'swr';

export interface Configuration {
  permissions: Permission[];
  enabledFeatures: EnabledFeature[];
}

export enum EnabledFeature {
  DEBUG_MODE = 'DEBUG_MODE',
  MULTI_LANGUAGE = 'MULTI_LANGUAGE',
  EDIT_MERCHANT_REPORTING_DETAILS = 'EDIT_MERCHANT_REPORTING_DETAILS',
  CREDIT_INVOICES_CREATION = 'CREDIT_INVOICES_CREATION',
  DECLINE_REFUNDS = 'DECLINE_REFUNDS',
  ENABLE_DANKORT = 'ENABLE_DANKORT',
  ENABLE_BANKAXEPT = 'ENABLE_BANKAXEPT',
  AMEX_OFFBOARDING = 'AMEX_OFFBOARDING',
  WRITE_BANK_DETAILS = 'WRITE_BANK_DETAILS',
  BANK_DETAILS_CHANGE_WITH_SALESFORCE_TICKET = 'BANK_DETAILS_CHANGE_WITH_SALESFORCE_TICKET',
}

export const useConfiguration = () => {
  return useSWR<Configuration>(['/configuration'], url => fetchByUrl(url), {
    refreshInterval: 5000,
  });
};
