import { MenuItem, SubMenuItem } from 'routes/NavigationBar';
import { useTranslation } from 'react-i18next';
import { Permission, useUserPermissions } from '../../permissions';

export const useServiceDeliveryMenuItems = (): MenuItem[] => {
  const { t } = useTranslation();
  const { permissions } = useUserPermissions();

  const subMenuItems: SubMenuItem[] = [];

  if (permissions.includes(Permission.READ_BANK_ACCOUNT_INFORMATION)) {
    subMenuItems.push({
      id: 'bank-account-update-requests',
      href: '/bank-account-update-requests',
      title: t('bank-account-update-requests.title'),
    });
  }

  return [
    {
      id: 'serviceDelivery',
      href: undefined,
      title: t('navigation.service-delivery'),
      icon: 'bank',
      subMenuItems,
    },
  ];
};
