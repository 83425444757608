import { Button } from '@loomispay/vault';
import { useTranslation } from 'react-i18next';
import { createToast } from 'vercel-toast';
import { fetchFromBackend } from '../../utils/fetch';
import { BankAccountUpdateRequestActionProps } from './BankAccountUpdateRequestActionProps';

export const RejectBankAccountChangeButton = ({ request, onAction }: BankAccountUpdateRequestActionProps) => {
  const { t } = useTranslation();

  const url = `/bank-account-information-update-request/${request.id}/reject`;

  const rejectBankAccountChange = async () => {
    await fetchFromBackend(url, {
      method: 'PUT',
    }).then(response => {
      if (response.ok) {
        createToast(t('bank-account-update-requests.table.reject.success'), {
          timeout: 10000,
          type: 'success',
        });
        onAction();
      } else {
        createToast(t('bank-account-update-requests.table.reject.error'), {
          timeout: 6000,
          type: 'error',
        });
      }
    });
  };

  return (
    <Button
      label={t('bank-account-update-requests.table.reject.action')}
      onClick={() => {
        rejectBankAccountChange();
      }}
      variant={'destructive'}
    />
  );
};
